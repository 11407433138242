/* You can add global styles to this file, and also import other style files */
html {
    width:100%;
    height:100%;
    background: rgb(6,6,6);
    background: -moz-linear-gradient(180deg, rgba(6,6,6,1) 0%, rgba(59,45,46,1) 23%, rgba(6,6,6,1) 42%);
    background: -webkit-linear-gradient(180deg, rgba(6,6,6,1) 0%, rgba(59,45,46,1) 23%, rgba(6,6,6,1) 42%);
    background: linear-gradient(180deg, rgba(6,6,6,1) 0%, rgba(59,45,46,1) 23%, rgba(6,6,6,1) 42%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#060606",endColorstr="#060606",GradientType=1);
}

body {
    padding: 0;
    margin: 0;
    height:100%;
    overflow:hidden;
}